import type { ThemeVars } from '@storybook/theming';
import { create } from '@storybook/theming';

export const theme = create({
  base: 'light',
  colorPrimary: '#f4616b',
  colorSecondary: '#24466c',
  brandTitle: 'Fernarzt Storybook',
  brandUrl: 'https://fernarzt.com/',
  brandImage: '/logo/logo.svg',
}) satisfies ThemeVars;
